window.Client = {
	init: function() {
		// Do something on initialization
	},

onRender: function(screenId) {
      if (screenId === 'id-04fadf8c') {
        window.lintrk('track', { conversion_id: 6254316 });
      } else if (screenId === 'start') {
        window.lintrk('track', { conversion_id: 0222 });
      } else if (screenId === 'id-8a822789') {
        window.lintrk('track', { conversion_id: 0122 });
      }
    }  
}

